<template>
  <div class="container">
    <head-top :ucAuth="ucSetting"></head-top>

    <!-- 修改密码页面 -->
<!--    <changePsd v-if="changePsdVal == 'Y'" class="changePsd"></changePsd>-->

    <div class="index-content">
      <!-- 数字驾驶舱 -->
      <div class="menuBox" v-for="(item,index) in models" @click="goPath(item)" :key="item.id">
        <div class="menuIcon">
          <img class="img1" src="../../src/assets/images/index/menuIcon.png" alt="">
          <img class="img2" src="../../src/assets/images/index/menuIcon1.png" alt="">
        </div>
        <p>{{item.name}}</p>
      </div>
    </div>
    <div class="mask"  v-if="showQrcode">
      <div class="qrCodeBox" :style="{backgroundImage:'url('+models[2].url+')'}">
        <!-- <img class="zzdLogin" src="../assets/images/index/qrcode_img.png" alt="暂无图片" /> -->
        <img class="close" src="../assets/images/jhe-3.png" @click.stop="showQrcode = false" alt="">
      </div>
    </div>

    <!-- 登录过期提示 -->
    <div class="mask" v-if="loginVal"></div>
    <div class="FixedImg loginTips" v-if="loginVal" @click="nologin">
      <span>{{ loginText }}</span>
      <p>×</p>
    </div>
    <!-- 无权限提示 -->
    <div class="FixedImg loginTips" v-if="powerVal" @click="noPower">
      <span>{{ powerText }}</span>
      <p>×</p>
    </div>
  </div>
</template>

<script>
import Api from "@/server";
import headTop from "@/components/headTop.vue";

export default {
  name: 'index',
  components: {
    headTop
  },
  data() {
    return {
      // 是否需要修改密码
      //changePsdVal: 'Y',

      // 登录过期提示
      loginVal: false,
      loginText: '登录已过期，请重新登录~',
      showQrcode: false,

      // 无权限提示
      powerVal: false,
      powerText: '暂无权限，请联系管理员~',

      powerArr: [],

      // admin 系统权限控制
      adminPath: '',
      adminMenuId: '',
      models: [],
      ucSetting: {},
      authList: [],
      qrCodePath:''
    }
  },
  methods: {
    getAuth() {
      let params = {
        systemId: "jhy"
      }
      Api.ajaxRequest('auth/query/permission', params).then(res => {
        if(res.code!=200){
          this.loginVal = true
        }
        let data = res.data
        this.authList = data
        this.getModels()
      })
    },
    menuGoto(path, id, child, name) {
      let leftId = id,
          leftName = name,
          moduleId,
          requset_moduleId = '';
      if (path == null || path == '') {
        if (child[0].url == null || child[0].url == '') {
          path = child[0].child[0].url;
          leftId = child[0].child[0].id;
          leftName = child[0].child[0].name;
          requset_moduleId = child[0].child[0].moduleId;
        } else {
          path = child[0].url;
          leftId = child[0].id;
          leftName = child[0].name;
          requset_moduleId = child[0].moduleId;
        }
      }
      // 当菜单只有本级时
      let btnList = [];
      if (child.length > 0) {
        // 判断是否为按钮数组
        child[0].child.forEach(item => {
          if (item.type == '按钮' && item.child.length == 0) {
            btnList.push(item);
          } else if (item.type !== '按钮' && leftId == item.id) {
            btnList = [];
            item.child.forEach(el => {
              if (el.type == '按钮') {
                btnList.push(el);
              }
            })
          }
        })
      }
      moduleId = child[0] ? child[0].moduleId : -1;
      let activeItems = [];
      child.forEach((item, index) => {
        activeItems.push(index)
      })
      sessionStorage.setItem('admin_MeunInd', id);
      sessionStorage.setItem('admin_LeftId', leftId);
      sessionStorage.setItem('admin_pageName', leftName);
      sessionStorage.setItem('admin_moduleId', moduleId);
      sessionStorage.setItem('admin_btnList', JSON.stringify(btnList));
      sessionStorage.setItem('admin_activeItems', JSON.stringify(activeItems));
      sessionStorage.setItem("requset_moduleId", requset_moduleId);
    },
    goPath(params) {
      if(!params.isAuth){
        this.powerVal = true
        return false
      }
      if (params.type === '按钮' || params.url.indexOf('/appfile/')>=0) {
        this.qrCodePath = params.url
        this.showQrcode = true
        return false
      }
      if (params.url.indexOf('http') < 0) {
        if (params.url.indexOf('moduleId=') >= 0) {
          let params = {
            moduleId: params.url.split('moduleId=')[1],
            systemId: 'admin'
          }
          Api.ajaxRequest('auth/query/permission', params).then(res => {
            let moduleInfo = res.data[0]
            this.menuGoto(moduleInfo.url, moduleInfo.id, moduleInfo.child, moduleInfo.name)
            window.open(config.name + params.url.split('moduleId=')[0], params.target)
          })
        } else {
          window.open(config.name + params.url, params.target)
        }
      } else {
        window.open(params.url, params.target)
      }
    },
    getModels() {
      let params = {
        data: {
          systemId: "jhy"
        }
      }
      Api.ajaxRequest('module/query/tree', params).then(res => {
        let data = res.data
        data.forEach((item, index) => {
          this.authList.forEach((ite, indx) => {
            if(ite.id === item.id){
              item.isAuth = true
            }
          })
          if (item.url !== null && item.url.indexOf('/uc/') >= 0) {
            this.ucSetting = item
            data.splice(index, 1)
          }
        })
        this.models = data
      });
    },
    nologin() {
      this.loginVal = false;
      this.$router.push({path: '/'});
    },
    noPower() {
      this.powerVal = false;
    },
    logout() {
      let expireDate = new Date(); //获取时间
      expireDate.setTime(expireDate.getTime() + 100); //保存的天数
      window.document.cookie = "uid=null;path=/;expires=" + expireDate;
      window.document.cookie = "token=null;path=/;expires=" + expireDate;
      window.location.href = Api.urlAjax + "index.html#/";
    },
  },
  mounted() {
    this.getAuth()
  },
}
</script>

<style scoped lang="less">
@import "../assets/css/minxin";
.container {
  width: 100vw;
  height: 100vh;
  background-image: url(../assets/images/bg1.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .changePsd {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 200;
    top: 0;
    left: 0;
  }

  // 登录提示
  .mask {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, .5);
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    .qrCodeBox {
      position: absolute;
      width: 406px;
      height: 414px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-image: url(../assets/images/index/bg_qrcode.png);
      background-size: 100% 100%;

      .zzdLogin {
        width: 115px;
        height: 115px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -56%);
      }

      .close {
        width: 30px;
        height: 30px;
        position: absolute;
        right: -15px;
        top:-40px;
        cursor: pointer;
        transform: translate(50%, 50%);
        z-index: 20;
      }
    }
  }

  .loginTips {
    width: 435px;
    height: 97px;
    background-image: url(../assets/images/login/icon-loginTip.png);
    background-size: 100% 100%;
    color: #00e5ff;
    position: fixed;
    z-index: 1000;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 33px 20px 10px 40px;

    p {
      font-weight: bold;
      font-size: 28px;
      cursor: pointer;
    }

    span {
      font-size: 16px;
    }
  }

  .index-content {
    width: 1200px;
    height: 660px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .menuBox {
      width: 244px;
      height: 308px;
      cursor: pointer;
      background-image: url(../../src/assets/images/index/bg.png);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      position: relative;
      transition: all .2s linear;

      .menuIcon {
        width: 88px;
        height: 89px;
        position: relative;
        transition: all .2s linear;

        .img1 {
          width: 100%;
          height: 100%;
        }

        .img2 {
          width: 30px;
          height: 29px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      > p {
        color: #00c0ff;
        .add-size(@font_size_16);
        font-weight: bold;
      }

      &:hover {
        width: 256px;
        height: 324px;
        background-image: url(../../src/assets/images/index/bg-a.png);

        .menuIcon {
          transform: scale(1.1);

          .img1 {
            animation: rote1 4s linear infinite;
          }
        }

        > p {
          color: #fff;
        }
      }
    }

    // .menuBox:nth-child(1){
    // 	top: 10%;
    // 	left: 15%;
    // }
    // .menuBox:nth-child(2){
    // 	top: 50%;
    // 	left: 50%;
    // 	transform: translate(-50%, -50%);
    // }
    // .menuBox:nth-child(3){
    // 	top: 10%;
    // 	right: 15%;
    // }
  }
}

@keyframes rote1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
