<template>
  <div class="head-container">
		<div class="loginInfo" >
			<span>你好！{{username}}</span>
      <img @click="GOUc" src="../assets/images/setIcon.png" alt="" v-if="ucAuth.isAuth">
			<img @click="logout" src="../assets/images/logoutIcon.png" alt="">
      <div class="settingFont" @click="settingDialog=true">
         <div class="settingBox" v-if="settingDialog">
           <div class="close" @click.stop="settingDialog=false"></div>
           <div class="title">拖动设置字体大小</div>
           <div class="slider">
             <span>小</span>
             <el-slider
                 v-model="fontSize"
                 :show-tooltip="false"
                 :max="3"
                 @change="changeFontSize()"
                 :step="1"
                 show-stops>
             </el-slider>
             <span class="big">大</span>
           </div>
         </div>
      </div>
		</div>
  </div>
</template>

<script>
import Api from '@/server/index.js';
export default {
  name: 'headTop',
  props:{
    ucAuth: Object,
  },
	data(){
		return {
      settingDialog:false,
      fontSize:localStorage.getItem('fontSize')===null?1:+localStorage.getItem('fontSize'),
			username:'',
		}
	},
	mounted() {
		this.GetUserName();
    window.document.documentElement.setAttribute('data-size', this.fontSize)
	},
	methods: {
    changeFontSize(){
       localStorage.setItem('fontSize',this.fontSize.toString())
       window.document.documentElement.setAttribute('data-size', this.fontSize)
    },
		GOUc(){
      let params = this.ucAuth
      window.open(params.url.indexOf('http') >= 0 ? params.url : config.name + params.url, params.target)
		},
		logout(){
			var expireDate = new Date(); //获取时间
			expireDate.setTime(expireDate.getTime() + 100); //保存的天数
			window.document.cookie = "uid=null;path=/;expires=" + expireDate;
			window.document.cookie = "token=null;path=/;expires=" + expireDate;
			this.$router.push({
				path:'/'
			});
		},

		GetUserName(){
			let params =Api.formDataArg()
			Api.ajaxRequestas('user/get/info',params).then(res => {
				let {code,data} = res;
				if (code == '200') {
					this.username=data.realName
				}
			});
		},
	},
}
</script>

<style scoped lang="less">
@import "../assets/css/minxin";
.head-container{
	width: 100%;
	height: 160px;
	background-image: url(../assets/images/topBg.png);
	background-size: 1879px 153px;
	background-position: top center;
	background-repeat: no-repeat;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	.loginInfo{
    width: 20%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding-bottom: 20px;
    cursor: pointer;
		padding-right: 20px;
    .settingFont{
       background-image: url("../assets/images/jhe-2.png");
       background-repeat: no-repeat;
       background-size: contain;
       width:20px;
       height:20px;
       position:relative;
       margin-left:15px;
       .settingBox{
         background-image: url("../assets/images/jhe-1.png");
         background-size: contain;
         width:406px;
         height:194px;
         position:absolute;
         right:30px;
         top:49px;
         background-repeat: no-repeat;
         .title{
           font-size:24px;
           color:#00c0ff;
           display:flex;
           align-items: center;
           justify-content: center;
           padding:40px 0 20px 0;
         }
         .slider{
           display:flex;
           align-items: center;
           padding:0 20px;
           justify-content: space-between;
           span{
              font-size:16px;
              display:inline-block;
              color:#00dbf5;
              &.big{
                 font-size:30px;
              }
           }
           /deep/.el-slider{
              width:280px;
              margin:0 auto;
             &:hover{
               //transform: scale(1) !important;
               .el-slider__runway{
                 .el-slider__button-wrapper{
                   .el-slider__button{
                     background: url("../assets/images/jhe-7.png")  no-repeat;
                   }
                 }
               }
             }
             .el-slider__runway{
               background-color: #0976bf;
               height:8px;
               border-radius:4px;
               .el-slider__bar{
                 height:100%;
                 background-color: #03e2ea;
               }
               .el-slider__button.dragging, .el-slider__button.hover, .el-slider__button:hover{
                 transform: scale(1);
               }
               .el-slider__button-wrapper{
                 .el-slider__button{
                    width:30px;
                    height:14px;
                    background: url("../assets/images/ht-5.png")  no-repeat;
                    background-size: contain;
                    border-radius:0;
                    border:none;
                 }
               }

             }
             .el-slider__stop{
                background-color: #0a97f2;
                height:12px;
                border-radius:10px;
                top:-2px;
             }
           }
         }

         .close{
           background-image: url("../assets/images/jhe-3.png");
           background-size: contain;
           z-index:999;
           background-repeat: no-repeat;
           width:30px;
           height:30px;
           position: absolute;
           right:-28px;
           top:-15px;
         }
       }
    }
		>span{
			//font-size: 16px;
      .add-size(@font_size_14);
			line-height: 16px;
			color: #3cc1f9;
			cursor: pointer;
		}
		>img{
			width: 36px;
			height: 36px;
			margin-left: 10px;
			cursor: pointer;
		}
	}
}
</style>
